<!--
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2023-11-22 18:55:06
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-18 10:52:14
-->
<template>
  <div class="container-warp">
    <div class="detail-info-box edit-info-box" v-if="!editFlag">
      <div class="detail-info-box-title">
        <com-title>基本信息</com-title>
        <el-button class="delete-group-btn add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}" plain @click="_editBtn">编辑</el-button>
      </div>
      <el-row>
        <el-col :span="8">
          <div class="com-div">
            <span class="com-label">课程名称</span>
            <span class="com-text">{{courseInfo.courseName||'—'}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="com-div">
            <span class="com-label">课程编号</span>
            <span class="com-text">{{courseInfo.courseCode||'—'}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="com-div">
            <span class="com-label">课程分组</span>
            <span class="com-text">{{courseInfo.groupName||'—'}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="com-div">
            <span class="com-label">课程类型</span>
            <span class="com-text">{{courseInfo.courseType||'—'}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="com-div" v-if="$route.query.type == 1">
            <span class="com-label">是否发布</span>
            <span class="com-text">{{courseInfo.status == 1?'已发布':courseInfo.status == 2?'未发布':'—'}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="com-div">
            <span class="com-label">最近更新时间</span>
            <span class="com-text">{{courseInfo.updateTime||'—'}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" v-if="$route.query.type == 1&&courseInfo.status == 1">
          <div class="com-div">
            <span class="com-label">可见范围</span>
            <div class="com-text">
              <span v-for="(item,index) in courseInfo.useDeptIds" :key="index+item.id">{{item.name}}{{index == courseInfo.useDeptIds.length-1?'':','}}</span>
              <span v-for="(item,index) in courseInfo.useUserIds" :key="index+item.id">{{item.name}}{{courseInfo.useDeptIds.length?',':''}}</span>

            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="com-div">
            <span class="com-label">课程封面</span>
            <div class="img-box">
              <div v-for="(item,index) in courseInfo.cover?courseInfo.cover.split(','):[]" :key="index">
                    <el-image
                      :src="item"
                      :preview-src-list="courseInfo.cover?courseInfo.cover.split(','):[]">
                  </el-image>
                </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="com-div">
            <span class="com-label">课程描述</span>
            <span class="com-text"
              >{{courseInfo.description||'—'}}</span
            >
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="com-div">
            <span class="com-label">课程介绍</span>
            <span class="com-text editor" v-html="courseInfo.introduce"></span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="edit-info-box" v-if="editFlag">
      <div class="detail-info-box-title" style="margin-bottom: 21px">
        <com-title>基本信息</com-title>
      </div>
      <div class="edit-plan-box">
        <el-form ref="editForm" :model="editForm" :rules="rules" label-width="auto">
          <el-row>
            <el-col :span="8" style="padding-right: 32px">
              <el-form-item label="课程名称" prop="courseName">
                <el-input
                clearable
                  v-model="editForm.courseName"
                  placeholder="请输入课程名称"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="padding-right: 32px">
              <el-form-item label="课程编号" prop="courseCode">
                <el-input
                clearable
                  v-model="editForm.courseCode"
                  placeholder="请输入课程编号"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="padding-right: 32px">
              <el-form-item label="课程分组" prop="groupId">
                <GlobalTreeSelect
                  style="width: 330px"
                  v-model="editForm.groupId"
                  filterable
                  :treeProps="defaultProps"
                  :data="courseGroupList"
                  @input="_getBelongGroup"
                  >
                </GlobalTreeSelect>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" style="padding-right: 32px">
              <el-form-item label="课程类型" >
                <el-select style="width: 100%" clearable v-model="editForm.courseTypeId">
                  <el-option
                    v-for="item in courseTypeList"
                    :key="item.id"
                    :label="item.categoryName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="padding-right: 32px">
              <el-form-item label="是否发布" prop="planType">
                <el-switch v-model="editForm.isPublish"> </el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="editForm.isPublish">
            <el-col :span="16" style="padding-right: 32px">
              <el-form-item label="可见范围"  prop="visibilityRange">
                <VisibilityRange ref="visibilityRange" :courseInfo="editForm"/>
              </el-form-item>
            </el-col>
          </el-row>

      <el-row>
          <el-col :span="24" >
            <el-form-item label="课程封面">
              <el-upload
                name="files"
                ref="upload"
                drag
                multiple
                :limit="1"
                :action="uploadAction"
                :headers="uploadHeader"
                list-type="picture-card"
                :on-success="_handeSuccessImage"
                :on-change="_uploadCoverImage"
                :on-remove="_handleRemove"
                :on-preview="_preview"
                :file-list="imgList"
                :class="imgList.length>=1?'disabled':''"
                :style="[
                  { '--uploadBgColor': uploadBgColor },
                  { '--uploadBorderColor': $store.state.settings.theme },
                ]"
              >
                <div class="upload-box">
                  <i
                    class="iconfont icon-icon_new"
                    :style="{ color: $store.state.settings.theme }"
                  ></i>
                  <el-button type="text" style="margin-left: 0 !important"
                    >上传封面</el-button
                  >
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
          <el-row>
            <el-col :span="16" style="padding-right: 32px">
              <el-form-item label="课程描述">
                <el-input
                  type="textarea"
                  autosize
                  v-model="editForm.description"
                  placeholder="请输入"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" style="padding-right: 32px">
              <el-form-item label="课程介绍">
                <RichTextEditor
                  class="editor"
                  ref="richTextEditor"
                  :content="editForm.introduce"
                  @changeData="_getRichTextHtml"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="footer-box">
          <el-button @click="_cancelBtn">取消</el-button>
          <el-button type="primary" @click="_define">保存</el-button>
        </div>
      </div>
    </div>
      <!-- 大图预览 -->
      <el-image-viewer
      v-if="dialogVisible"
      :on-close="closeImgViewer"
      :url-list="imagePreviewUrls"
      :z-index="3000"
    />
  </div>
</template>

<script>
import comTitle from '@/components/comTitle/index.vue'
import GlobalTreeSelect from '@/components/globalTreeSelect/index.vue'
import VisibilityRange from '@/components/visibilityRange/index.vue'
import RichTextEditor from '@/components/richTextEditor/index.vue'
import { courseBaseInforEditApi, getCourseTreeApi } from '@/api/course'
import { getFindCustomizeTypeApi } from '@/api/settings'
import { getCookie } from '@/utils/util'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: {
    comTitle,
    GlobalTreeSelect,
    VisibilityRange,
    RichTextEditor,
    ElImageViewer
  },
  props: {
    courseInfo: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      editFlag: false,
      editForm: {
        cover: '',
        groupId: '',
        courseName: '', // 课程名称
        courseCode: '', // 课程编号
        groupName: '', // 课程分组
        courseType: '', // 课程类型
        courseTypeId: '',
        status: false, // 是否发布
        description: '', // 课程描述
        introduce: ''// 课程介绍
      },
      rules: {
        courseName: [
          { required: true, message: '请输入课程名称', trigger: 'change' }
        ],
        courseCode: [
          { required: true, message: '请输入课程编号', trigger: 'change' }
        ],
        groupId: [
          { required: true, message: '请选择课程分组', trigger: 'change' }
        ]
      },
      courseGroupList: [],
      courseTypeList: [],
      uploadBgColor: '',
      defaultProps: {
        children: 'children',
        label: 'groupName'
      },
      refreshRichTextEditorKey: new Date().getTime(),
      uploadAction: process.env.VUE_APP_BASE_API_IMAGE + '/oss-service/ossObject/upload', // 上传图片路径
      uploadHeader: {
        token: getCookie('token')
      },
      imgList: [],
      dialogVisible: false, // 图片预览
      imagePreviewUrls: []
    }
  },
  mounted () {
    this._query()
    this.getCourseType()
    this.uploadBgColor = this._hexToRgb(this.$store.state.settings.theme)
  },
  methods: {
    _getRichTextHtml (html) {
      this.editForm.introduce = html
    },
    getCourseType () {
      // 课程类型
      getFindCustomizeTypeApi({ type: 0 }).then((res) => {
        if (res.code === 200) {
          this.courseTypeList = res.data.scheduleType
        }
      })
    },
    _query (type) {
      // 课程查询
      getCourseTreeApi({ type: this.$route.query.type }).then(
        (res) => {
          if (res.code === 200) {
            this.courseGroupList = res.data
          }
        }
      )
    },
    // 所属分组--取值
    _getBelongGroup (value) {
      this.editForm.group = value || 0
      this.editForm.parentId = value || 0
    },
    // 点击编辑
    _editBtn () {
      this.editFlag = true
      this.editForm = JSON.parse(JSON.stringify(this.courseInfo))
      if (this.courseTypeList.some(item => item.id == this.editForm.courseTypeId)) {
        this.editForm.courseTypeId = this.courseInfo.courseTypeId
      } else {
        this.editForm.courseTypeId = null
      }
      // this.courseTypeList.forEach(item => {
      //   console.log(item.id, this.editForm.courseTypeId)
      //   if (item.id == this.editForm.courseTypeId) {
      //     this.editForm.courseTypeId = this.courseInfo.courseTypeId
      //   } else {
      //     this.editForm.courseTypeId = null
      //   }
      // })
      this.imgList = this.editForm.cover ? this.editForm.cover.split(',').map(item => {
        return {
          url: item
        }
      }) : []
      if (this.courseInfo.status == 1) {
        this.$set(this.editForm, 'isPublish', true)
      } else if (this.courseInfo.status == 2) {
        this.$set(this.editForm, 'isPublish', false)
      }
    },
    _define () {
      // 保存
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const useUserId = []
          const useDeptId = []
          if (this.$route.query.type == 1) {
            if (this.editForm.isPublish && (!this.editForm.useUserIds.length && !this.editForm.useDeptIds.length)) {
              this.$message.error('请选择可见范围！')
              return
            }

            this.editForm.useUserIds.forEach(item => {
              useUserId.push(item.id)
            })
            this.editForm.useDeptIds.forEach(item => {
              useDeptId.push(item.id)
            })
          }
          if (this.editForm.isPublish) {
            this.editForm.status = 1
          } else {
            this.editForm.status = 2
          }
          courseBaseInforEditApi({
            ...this.editForm,
            useUserId: useUserId.length ? useUserId.toString() + ',' : '',
            useDeptId: useDeptId.length ? useDeptId.toString() + ',' : ''
          }).then(res => {
            if (res.code === 200) {
              this.editFlag = false
              this.$emit('updatedDetail')
              this.$message.success('操作成功！')
            }
          })
        }
      })
    },
    closeImgViewer () {
      this.dialogVisible = false
    },
    _preview (file) {
      // 图片预览
      this.dialogVisible = true
      this.imagePreviewUrls = file.url ? [file.url] : [file.response && file.response.data[0].url]
    },
    // 学习
    _cancelBtn () {
      this.editFlag = false
    },
    // 图片上传成功
    _handeSuccessImage (response, file, fileList) {
      this.imgList = fileList
      const arr = []
      if (fileList.length > 0) {
        fileList.forEach(item => {
          if (item.response) {
            arr.push(item.response.data[0].url)
          } else {
            arr.push(item.url)
          }
        })
      }
      this.editForm.cover = arr.join(',')
    },
    // 上传封面图触发
    _uploadCoverImage (file, fileList) {

    },
    // 删除图片
    _handleRemove (file, fileList) {
      this.imgList = fileList
      let arr = []
      if (fileList.length > 0) {
        fileList.forEach(item => {
          arr.push(item.response.data[0].url)
        })
      } else {
        arr = []
      }
      this.editForm.cover = arr.join(',')
    },
    // 悬浮颜色
    _hexToRgb (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      this.$forceUpdate()
      return result
        ? 'rgba' +
            '(' +
            parseInt(result[1], 16) +
            ',' +
            parseInt(result[2], 16) +
            ',' +
            parseInt(result[3], 16) +
            ',' +
            0.2 +
            ')'
        : null
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-info-box {
  padding-bottom: 203px;
  .detail-info-box-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.com-div {
  display: flex;
  margin-top: 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  .img-box {
    img {
      width: 90px;
      height: 90px;
    }
  }
}
.com-label {
  margin-right: 16px;
  color: #999999;
}
.com-text{
  flex: 1;
}
/deep/.el-upload--picture-card {
  background: var(--uploadBgColor) !important;
  border-color: var(--uploadBorderColor) !important;
  width: 90px;
  height: 90px;
  line-height: 20px;
  .upload-box {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    .iconfont {
      font-size: 17px;
    }
  }
}
/deep/.el-upload-dragger {
  width: 90px !important;
  height: 90px !important;
  background-color: transparent;
  border: 1px dashed transparent;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 90px;
  height: 90px;
}
/deep/.el-upload-list--picture-card .el-progress {
  width: 90px !important;
}
/deep/.el-progress-circle {
  width: 90px !important;
  height: 90px !important;
}
/deep/.el-form-item__label {
  color: rgba(0, 0, 0, 0.9);
}
/deep/.ql-container {
  min-height: 122px;
}
.footer-box {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 217px;
  /deep/.el-button {
    margin-left: 16px !important;
    font-size: 14px;
    height: 32px;
  }
}
.edit-info-box{
  height:  calc(100vh - 440px);
  overflow: auto;
}
/deep/.el-image{
    width: 90px;
    height: 90px;
    margin-right: 10px;
  }
  /deep/.disabled .el-upload--picture-card {
    display: none !important;
}

/deep/.disabled .el-button--success.is-plain {
    display: none !important;
}
/deep/.editor{
  img{
    width: 200px;
    height: 200px;
  }
}
.delete-group-btn{
  width: 60px;
}
</style>
